export const SET_BANNER_VIEW = 'SET_BANNER_VIEW';
export const SET_MENU_VIEW = 'SET_CONTACT_VIEW';
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';
export const EMAIL_FAILURE = 'EMAIL_FAILURE';
export const APPLICATION_SUCCESS = 'APPLICATION_SUCCESS';
export const APPLICATION_FAILURE = 'APPLICATION_FAILURE';
export const APPLICATION_FAILURE_RESET = 'APPLICATION_FAILURE_RESET';
export const CONTACT_FAILURE_RESET = 'CONTACT_FAILURE_RESET';
export const APPLICATION_ON_SUCCESS = 'APPLICATION_ON_SUCCESS';
export const CONTACT_ON_SUCCESS = 'CONTACT_ON_SUCCESS';
export const APPLICATION_REDIRECT_RESET = 'APPLICATION_REDIRECT_RESET';
export const CONTACT_REDIRECT_RESET = 'CONTACT_REDIRECT_RESET';
