import React from 'react';

function Gradient(props) {
  return (
    <div
      className='gradient
  '
    ></div>
  );
}

export default Gradient;
