const navArray = [
  { route: '', mainItem: 'Home', subMenu: ['Our Story'] },
  {
    route: 'industry',
    mainItem: 'Industries We Server',
    subMenu: ['Oil and Gas', 'Construction'],
  },
  { route: 'careers', mainItem: 'Careers', subMenu: [] },
  { route: 'hse', mainItem: 'Accountability', subMenu: [] },
  {
    route: 'location',
    mainItem: 'Locations',
    subMenu: ['85 Vickers Rd, Scarborough', '45 Comstock Rd Etobicoke'],
  },
  { route: 'contact', mainItem: 'Contact Us', subMenu: [] },
];
export default navArray;
